import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { League, LeagueGame, Player, PlayerGameScore, RosterPositionEnum, NflGame } from '../../../sdk/model';
import { positionOrderNoBench } from '../../util/PositionOrder';
import { useAppSelector } from '../../../app/hooks';
import { Box } from '@mui/system';
import { getTeamFromLeague } from '../../util/TeamUtils';
import './style.scss';
import { usePlayerDataLoadFlat } from '../../util/PlayerDataUtil';
import { ScoreToolTip } from '../../util/GameUtil';
import PlayerCardLink from '../../cards/playerCardLink';
import { Properties } from '../../../Properties';
import { useUpdatePlayerDataFromMessage, useUpdateNflGameDataFromMessage } from '../../../app/dataLoaderHooks';
import useWebSocketListener from '../../web-sockets/WebSocketListener';
import playerMatchupDisplay from '../../util/PlayerMatchupDisplay';

const emptyPosition = "Empty";

const COMPLETED_GAME_STATUSES = new Set(['COMPLETE', 'CLOSED']);

interface MatchStartersProps {
  league: League,
  game: LeagueGame
}


function MatchupStarters(props: MatchStartersProps) {
  const playerMap = usePlayerDataLoadFlat();
  const playerStatsWebSocketUrl = Properties.websocketsUrl + '/ws/playerStats/genericUser/genericKey';
  useWebSocketListener(playerStatsWebSocketUrl, useUpdatePlayerDataFromMessage, []);
  const nflGames = useAppSelector((state) => state.nflGameDataLoad );
  const nflGameWebSocketUrl = Properties.websocketsUrl + '/ws/nflGames/genericUser/' + props.game.seasonYear + '-' + props.game.weekNumber;
  useWebSocketListener(nflGameWebSocketUrl, useUpdateNflGameDataFromMessage, []);
  const nflGameMap = new Map(nflGames.map(nflGame => [nflGame.id!, nflGame]));
  // This spreads confetti on the player name that won (in theory)
  const rosterSettings: { [key: string]: number; } | undefined = props.league.rosterSettings;
  const starters = positionOrderNoBench.map((position) => {
    return (
      getRowsForPosition(position, rosterSettings, props.game, playerMap, props.league.id, nflGameMap)
    );
  })

  const bench = getRowsForPosition(RosterPositionEnum.Bn , rosterSettings, props.game, playerMap, props.league.id, nflGameMap);
  const irPlayers = getRowsForPosition(RosterPositionEnum.Ir , rosterSettings, props.game, playerMap, props.league.id, nflGameMap);

  return (
    <Box>
      <TableContainer className='starters-table'>
        <Table className="player-info-table">
          <TableHead>
            <TableRow>
              <TableCell className="team-row" key="home-header">{getHeaderCell(props.game, props.league, true)}</TableCell>
              <TableCell className="position-row" key="spacer-header" ></TableCell>
              <TableCell  className="team-row" key="away-header">{getHeaderCell(props.game, props.league, false)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getStatersHeaderRow(props.game, props.league)}
            {starters}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className='bench-table'>
        <Table className="player-info-table">
          <TableHead>
            <TableRow>
              <TableCell className="team-row" key="home-header"></TableCell>
              <TableCell className="position-row" key="spacer-header" ></TableCell>
              <TableCell  className="team-row" key="away-header"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getBenchHeaderRow(props.game, props.league)}
            {bench}
          </TableBody>
        </Table>
      </TableContainer>
      {Object.keys(props.league.rosterSettings!).includes(RosterPositionEnum.Ir) &&
        <TableContainer className='ir-table'>
          <Table className="player-info-table">
            <TableHead>
              <TableRow>
                <TableCell className="team-row" key="home-header"></TableCell>
                <TableCell className="position-row" key="spacer-header" ></TableCell>
                <TableCell  className="team-row" key="away-header"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getIrHeaderRow(props.game, props.league)}
              {irPlayers}
            </TableBody>
          </Table>
        </TableContainer>
      }
      
    </Box>
  );
}

function getHeaderCell(game: LeagueGame, league: League, home: boolean) {
  const teamId = home ? game.homeTeamId : game.awayTeamId;
  const score = home ? game.homeTeamScore : game.awayTeamScore;
  const projection = home ? game.homeTeamProjection : game.awayTeamProjection;

  const winner = game.winningTeamId === teamId && game.finalScore;
  const scoreClass = game.winningTeamId === teamId ? "winning-team" : "losing-team";


  const finishBox = game.finalScore ? 
        (winner ? <Box className="winner-box">Winner</Box> : <Box className="loser-box">Loser</Box>) :
        "";

  const team = getTeamFromLeague(league, teamId);
  if (home)
    return (
        <Box className="score-container">
          <Box className="starter-team-name">{finishBox} {team?.name}</Box>
          <Box className={"starter-main-score " + scoreClass}>Score: {score} ({projection})</Box>
        </Box>
    );
  else
    return (
      <Box className="score-container">
        <Box id="testConf" className={"starter-main-score " + scoreClass}>Score: {score} ({projection})</Box>
        <Box className="starter-team-name">{finishBox} {team?.name}</Box>
      </Box>
    );
}

function getStatersHeaderRow(game: LeagueGame, league: League) {
  const gameState = game.finalScore ? "Final" : "In Progress";
  return (
  <TableRow className='starters head-row'>
    <TableCell><Box className='head-text'>Starters</Box></TableCell>
    <TableCell></TableCell>
    <TableCell>
      <Box className={'gameState'}>
        <Box component="span">Game State:</Box>
        <Box component="span" className={gameState}>{gameState}</Box>
      </Box>
    </TableCell>
  </TableRow>
  );
}


function getBenchHeaderRow(game: LeagueGame, league: League) {
  return (
  <TableRow className='bench head-row' key="bench">
    <TableCell colSpan={3}><Box className='head-text'>Bench</Box></TableCell>
  </TableRow>
  );
}

function getIrHeaderRow(game: LeagueGame, league: League) {
  return (
  <TableRow className='bench head-row' key="bench">
    <TableCell colSpan={3}><Box className='head-text'>Injured Reserve (IR)</Box></TableCell>
  </TableRow>
  );
}

function getRowsForPosition(position: string,
  rosterSettings: { [key: string]: number; } | undefined,
  game: LeagueGame,
  playerMap: Map<string | undefined, Player | undefined>,
  leagueId: string | undefined, nflGameMap: Map<string, NflGame>) {
  if (rosterSettings === null || rosterSettings === undefined) {
    return (
      <React.Fragment></React.Fragment>
    );
  }

  const numTimes = rosterSettings[position] || 0;

  return (
    <React.Fragment>
      {Array.from({ length: numTimes }).map((_, i) => (
        <TableRow key={`${position}-${i}`}>
          {/* Render table cells for each position */}
          <TableCell>{getPlayerFromRoster(game?.homeTeamRoster, playerMap, leagueId, position, i, true, nflGameMap)}</TableCell>
          <TableCell><Box className="score-position">{position}</Box></TableCell>
          <TableCell>{getPlayerFromRoster(game?.awayTeamRoster, playerMap, leagueId, position, i, false, nflGameMap)}</TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

// TODO: Make this return a cell with exactly what we want to display
function getPlayerFromRoster(roster: { [key: string]: Array<PlayerGameScore> } | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  leagueId:string | undefined, position: string, index: number, homeTeam: boolean, nflGameMap: Map<string, NflGame>) {
  if (roster === null || roster === undefined) {
    return <span style={{ color: 'red' }}>ERR</span>;
  }
  const playersAtPosition = roster[position];
  if (playersAtPosition === undefined || playersAtPosition[index] === undefined) {
    return emptyPosition;
  }
  const playerId = playersAtPosition[index].playerId;
  const playerInfo = playerMap.get(playerId);
  var playerNameInfo = playerInfo?.abbr_name;

  const currentWeekStats = playerInfo?.quick_stats?.currentWeeksStats;
  const nflGame = currentWeekStats ? nflGameMap.get(currentWeekStats.gameId!) : undefined;
  const score = playersAtPosition[index].totalScore !== undefined ? "Score: " + playersAtPosition[index].totalScore : "-";
  const completedGame = COMPLETED_GAME_STATUSES.has(nflGame?.status!);
  const rawProjection = completedGame ? playersAtPosition[index].originalProjectedScore  : playersAtPosition[index].projectedScore;
  const projectedScore = playerInfo ? (completedGame ? "Orig Proj: " : "Proj: ") + (rawProjection ? rawProjection!.toFixed(2) : "0.00") : '';
  const mobileView = window.innerWidth <= 768;

  if (homeTeam) {
    return (
      <Box className="score-container" sx={mobileView ? {display: 'inline-block'} : {}} >
        <Box className="starter-player">{playerInfo ? <PlayerCardLink player={playerInfo} leagueId={leagueId} /> : 'Empty'}</Box>
        <Box className="starter-player-opponent">{playerInfo ? playerMatchupDisplay(currentWeekStats, nflGame) : '-'}</Box>
        {mobileView && <br/>}
        <Box className="starter-player-score"><ScoreToolTip playerGameScore={playersAtPosition[index]}>
          <Typography sx={{fontSize: 18}}>{score}</Typography>
          <Typography sx={{fontSize: 12}}>{projectedScore}</Typography>
        </ScoreToolTip></Box>
      </Box>
    );
  } else if (mobileView) {
    return (
      <Box className="score-container" sx={{display: 'inline-block'}} >
        <Box className="starter-player">{playerInfo ? <PlayerCardLink player={playerInfo} leagueId={leagueId} /> : 'Empty'}</Box>
        <Box className="starter-player-opponent">{playerInfo ? playerMatchupDisplay(currentWeekStats, nflGame) : '-'}</Box>
        <br/>
        <Box className="starter-player-score"><ScoreToolTip playerGameScore={playersAtPosition[index]}>
          <Typography sx={{fontSize: 18}}>{score}</Typography>
          <Typography sx={{fontSize: 12}}>{projectedScore}</Typography>
        </ScoreToolTip></Box>
      </Box>
    );
  } else {
    return (
      <Box className="score-container">
        <Box className="starter-player-score"><ScoreToolTip playerGameScore={playersAtPosition[index]}>
          <Typography sx={{fontSize: 18}}>{score}</Typography>
          <Typography sx={{fontSize: 12}}>{projectedScore}</Typography>
        </ScoreToolTip></Box>
        <Box className="starter-player-opponent">{playerInfo ? playerMatchupDisplay(currentWeekStats, nflGame) : '-'}</Box>
        <Box className="starter-player">{playerInfo ? <PlayerCardLink player={playerInfo} leagueId={leagueId} /> : 'Empty'}</Box>
      </Box>
    );
  }
}

export default MatchupStarters;
