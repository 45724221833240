import { GameScoreBreakdown, NflPlayerGameStatistics, Passing, PassingScoringSettings, SettingWithBonuses, Fumbles, Player, PlayerGameScore, Receiving, ReceivingScoringSettings, Rushing, RushingScoringSettings, ScoringSettings, Statistic, NflGame } from "../../sdk/model";


const ALL_STRING : string = "ALL";
const SECONDS_IN_QUARTER : number = 15 * 60;
const SECONDS_IN_GAME : number = SECONDS_IN_QUARTER * 4;

export function getTotalScoreFromStats(stats: Statistic | undefined, scoringSettings: ScoringSettings, key : string, playerScoreForBreakdown: PlayerGameScore | undefined) : number | undefined {
  if (!stats) {
    return undefined;
  }

  let passing: number = 0;
  if (stats.passing) {
    passing = getPassingScore(getSettingsWithKey(scoringSettings.passingScoringSettings, key), stats.passing ,playerScoreForBreakdown);
  }

  let rushing: number = 0;
  if (stats.rushing) {
    rushing = getRushingScore(getSettingsWithKey(scoringSettings.rushingScoringSettings, key), stats.rushing, playerScoreForBreakdown);
  }

  let receiving: number = 0;
  if (stats.receiving) {
    receiving = getReceivingScore(getSettingsWithKey(scoringSettings.receivingScoringSettings, key), stats.receiving, playerScoreForBreakdown);
  }
  
  let fumbles: number = 0;
  if (stats.fumbles)  {
    fumbles = getFumblesScore(getSettingsWithKey(scoringSettings.fumbles, key), stats.fumbles, playerScoreForBreakdown);
  } 
  return round(passing + rushing + receiving + fumbles);
}

export function getPlayerCurrentScoreFromStats(player: Player, scoringSettings: ScoringSettings, nflGameMap: Map<string, NflGame> | undefined) : PlayerGameScore {
  return getPlayerScoreFromStats(player, scoringSettings, player.quick_stats?.currentWeeksStats || {}, nflGameMap);
}

function getTotalSecondsRemaining(game : NflGame) {
  if (game && game.clock) {
    const minuteSecondSplit = game.clock?.split(':')!;
    const secondsRemainingInQuarter = Number(minuteSecondSplit[0]) * 60 + Number(minuteSecondSplit[1]);
    const fullQuartersRemaining = 4 - game.quarter!;
    return Math.max(secondsRemainingInQuarter + fullQuartersRemaining * SECONDS_IN_QUARTER, 0);
  }
  return SECONDS_IN_GAME;
}

export function getPlayerScoreFromStats(player: Player ,scoringSettings: ScoringSettings, playerStats: NflPlayerGameStatistics, nflGameMap: Map<string, NflGame> | undefined) : PlayerGameScore {
  let playerScore: PlayerGameScore = {};
  const key : string = (player.position ?? "ALL").toString().toUpperCase();
  playerScore.playerId = player.id;
  playerScore.totalScore = getTotalScoreFromStats(playerStats.statistics, scoringSettings, key, playerScore);
  const originalProjection = getTotalScoreFromStats(playerStats.projections, scoringSettings, key, undefined)
  playerScore.originalProjectedScore = originalProjection;
  if (!originalProjection) {
    playerScore.projectedScore = playerScore.totalScore;
  } else if (!nflGameMap) {
    playerScore.projectedScore = originalProjection;
  } else {
    const currentScore = playerScore.totalScore ? playerScore.totalScore : 0;
    const projectedPointsPerSecond = originalProjection / SECONDS_IN_GAME;
    const game = nflGameMap.get(player.quick_stats?.currentWeeksStats?.gameId!)!;
    const totalSecondsRemaining = getTotalSecondsRemaining(game);
    playerScore.projectedScore = currentScore + projectedPointsPerSecond * totalSecondsRemaining;
  }
  return playerScore;
 

}

function getPassingScore(scoringSettings: PassingScoringSettings, passingStatistics: Passing, playerScore: PlayerGameScore | undefined) : number {
  let score = 0;

  if (!passingStatistics) {
    return score;
  }

  let yards : number = (passingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Passing Yards", passingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && passingStatistics?.yards && passingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Passing Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (passingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Passing TDs", passingStatistics?.touchdowns, scoringSettings.tds, tds);

  const conversions: number = (passingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Passing Conversions", passingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const ints : number = (passingStatistics?.interceptions ?? 0) * (scoringSettings?.interceptions ?? 0);
  populateScoreBreakdown(playerScore, "Interceptions", passingStatistics?.interceptions, scoringSettings.interceptions, ints);

  const sacks : number = (passingStatistics?.sacks ?? 0) * (scoringSettings?.sacked ?? 0);
  populateScoreBreakdown(playerScore, "Sacks", passingStatistics?.sacks, scoringSettings.sacked, sacks);

  const firstDowns : number = (passingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);
  populateScoreBreakdown(playerScore, "Passing First Downs", passingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + conversions + ints + sacks + firstDowns;
  return score;
}

function getRushingScore(scoringSettings: RushingScoringSettings, rushingStatistics: Rushing, playerScore: PlayerGameScore | undefined) {
  let score = 0;

  if (!rushingStatistics) {
    return score;
  }

  let yards : number = (rushingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Rushing Yards", rushingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && rushingStatistics?.yards && rushingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Rushing Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (rushingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Rushing TDs", rushingStatistics?.touchdowns, scoringSettings.tds, tds);

  const conversions: number = (rushingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Rushing Conversions", rushingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const firstDowns : number = (rushingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);  
  populateScoreBreakdown(playerScore, "Rushing First Downs", rushingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + conversions + firstDowns;

  return score;
}

function getReceivingScore(scoringSettings: ReceivingScoringSettings, rushingStatistics: Receiving, playerScore: PlayerGameScore | undefined) {
  let score = 0;

  if (!rushingStatistics) {
    return score;
  }

  let yards : number = (rushingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Receiving Yards", rushingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && rushingStatistics?.yards && rushingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Receiving Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (rushingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Receiving TDs", rushingStatistics?.touchdowns, scoringSettings.tds, tds);

  const receptions: number = (rushingStatistics?.receptions ?? 0) * (scoringSettings?.receptions ?? 0);
  populateScoreBreakdown(playerScore, "Receptions", rushingStatistics?.receptions, scoringSettings.receptions, receptions);

  const conversions: number = (rushingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Receiving Conversions", rushingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const firstDowns : number = (rushingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);  
  populateScoreBreakdown(playerScore, "Receiving First Downs", rushingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + receptions + conversions + firstDowns;

  return score;


}

function getFumblesScore(scoringSettings: SettingWithBonuses, fumbles: Fumbles, playerScore: PlayerGameScore | undefined) {
  let score = 0;

  if (!fumbles) {
    return score;
  }

  let lostFumbles : number = (fumbles?.lostFumbles ?? 0) * (scoringSettings?.points ?? 0);
  populateScoreBreakdown(playerScore, "Fumbles Lost", fumbles?.lostFumbles, scoringSettings?.points, lostFumbles);

  return lostFumbles;
}

function populateScoreBreakdown(playerScore: PlayerGameScore | undefined, name: string, stat: number | undefined, pointsPer: number | undefined, pointsTotal: number) {
  if (!playerScore) {
    return;
  }
  if(!playerScore.scoreBreakdown){
    playerScore.scoreBreakdown = [];
  }
  if(!stat){
    return;
  }
  let scoreBreakdown: GameScoreBreakdown = {}
  scoreBreakdown.statType = name;
  scoreBreakdown.stat = stat;
  scoreBreakdown.pointsPer = pointsPer;
  scoreBreakdown.points = round(pointsTotal);
  playerScore.scoreBreakdown.push(scoreBreakdown);
}

// Utility Method

function getSettingsWithKey(scoringSettings: { [key: string]: any; }, key: string) {
  if(!scoringSettings) {
    console.log("No scoring settings found");
    return {};
  }
  if (scoringSettings[key]) {
    return scoringSettings[key];
  }
  return scoringSettings[ALL_STRING];
}

function round(value: number): number {
  return parseFloat(value.toFixed(2));

}


/**
 * Shouldn't be exported into production
 */
export const exportedForTesting = {
  getPassingScore
}
